import React from "react"
import Icon from "../Icon/Icon"
import { IconsEnum } from "../../constants/icons"
import "./Parameter.css"
import { cls } from "../../helpers"

interface Props {
  icon: IconsEnum
  isActive: boolean
  isFaded?: boolean
}

class Parameter extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={cls(
          `parameter`,
          this.props.isActive && `parameter--active`,
          this.props.isFaded ? `parameter--disabled` : false,
        )}
      >
        <Icon icon={this.props.icon} />
      </div>
    )
  }
}

export default Parameter
