import React from "react"
import Icon from "../Icon/Icon"
import "./FilterSummary.css"
import { cls } from "../../helpers"

interface IFilterSummaryProps {
  title: string
  count: number
  isActive: boolean
  onClick: () => void
  className?: string
}

export default function FilterSummary({
  onClick,
  title,
  count,
  isActive,
  className,
}: IFilterSummaryProps) {
  return (
    <legend className={cls("filter-summary", className)}>
      <button onClick={onClick}>
        <span>
          {title} {count > 0 && `(${count})`}
        </span>
        <Icon icon={isActive ? "ICON_MINUS" : "ICON_PLUS"} scale={3} />
      </button>
    </legend>
  )
}
