import React from "react"
import Icon from "../Icon/Icon"
import Link from "../Link/Link"
import "./NavBar.css"
import { IconsEnum } from "../../constants/icons"

export interface NavBarItem {
  link: string
  icon: IconsEnum
  titleShort: string
  titleLong: string
}

export type NavBarItems = NavBarItem[]

export interface Props {
  items: NavBarItems
  isOpen: boolean
  closeNav: () => void
}

const NavBar = ({ items, isOpen, closeNav }: Props) => {
  return (
    <nav
      className={`nav-bar nav-bar--mobile ${
        isOpen ? `nav-bar--open nav-bar--mobile--open` : ``
      }`}
    >
      <ul className={`nav-bar__list`}>
        {items.map((item, idx) => (
          <li key={idx} onClick={closeNav}>
            <Link to={item.link} className={`nav-bar__link`}>
              <Icon
                icon={item.icon}
                caption={isOpen ? item.titleLong : item.titleShort}
              />
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default NavBar
