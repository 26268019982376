import React from "react"
import "./Hamburger.css"
import { cls } from "../../helpers"

interface Props {
  isActive: boolean
}

class Hamburger extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={cls(`hamburger`, this.props.isActive && `hamburger--active`)}
      >
        <i />
        <i />
        <i />
      </div>
    )
  }
}

export default Hamburger
