import React, { ChangeEvent, FC, useCallback, useEffect, useRef } from "react"
import "./MultiRangeSlider.css"

interface MultiRangeSliderProps {
  min: number
  max: number
  step: number
  values: number[]
  unit: React.ReactNode
  onValueChange: (value: number, index: number) => void
}

const MultiRangeSlider: FC<MultiRangeSliderProps> = ({
  min,
  max,
  values,
  onValueChange,
  unit,
  step,
}) => {
  const [minVal, maxVal] = values
  const minValRef = useRef(min)
  const maxValRef = useRef(max)
  const range = useRef<HTMLDivElement>(null)

  const handleMinChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(Number(event.target.value), maxVal - 1)
    onValueChange(value, 0)
    minValRef.current = value
  }

  const handleMaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(Number(event.target.value), minVal + 1)
    onValueChange(value, 1)
    maxValRef.current = value
  }

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  return (
    <div className={"multi-range"}>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={minVal}
        onChange={handleMinChange}
        className="thumb thumb--left"
      />
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={maxVal}
        onChange={handleMaxChange}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">
          {minVal}&nbsp;{unit}
        </div>
        <div className="slider__right-value">
          {maxVal}&nbsp;{unit}
        </div>
      </div>
    </div>
  )
}

export default MultiRangeSlider
