import React from "react"
import Parameter from "../Parameter/Parameter"
import "./FilterList.css"
import { FilterType, filters } from "../../constants/filters"
import { cls } from "../../helpers"
import FilterInput from "../FilterInput/FilterInput"
import Icon from "../Icon/Icon"

interface Props {
  filter: {
    [key in FilterType]: string[]
  }
  onChange: (filter: FilterType, value: string, index?: number) => void
  onReset: (filter: FilterType) => void
}

interface State {
  isOpen: boolean
  openFilter: FilterType | null
}

class FilterList extends React.PureComponent<Props> {
  state: State = {
    openFilter: null,
    isOpen: false,
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  myRef = React.createRef<HTMLDivElement>()

  handleClickOutside = (e: any) => {
    if (!this.myRef?.current?.contains(e.target)) {
      this.setState(
        (prevState: State): State => ({ ...prevState, isOpen: false })
      )
    }
  }

  handleToggle = () => {
    this.setState(
      (prevState: State): State => ({ ...prevState, isOpen: !prevState.isOpen })
    )
  }

  handleFilterClick = (filter: FilterType) => {
    return () =>
      this.setState((prevState: State) => ({
        ...prevState,
        openFilter: prevState.openFilter === filter ? null : filter,
      }))
  }

  handleRemoveFilter = (filter: FilterType) => {
    return () => {
      this.props.onReset(filter)
    }
  }

  render() {
    return (
      <div
        className={cls(`filter`, this.state.isOpen && "filter--open")}
        ref={this.myRef}
        onClick={this.handleClickOutside}
      >
        <button className={`filter__button`} onClick={this.handleToggle}>
          <span>Filter by</span>
        </button>
        <ul className={`filter__params`}>
          {filters.map(({ options, icon, type, title, key, settings }) => (
            <li key={key} className={`filter__param`}>
              <button title={title} onClick={this.handleFilterClick(key)}>
                <Parameter
                  icon={icon}
                  isFaded={
                    this.state.openFilter
                      ? this.state.openFilter !== key
                      : false
                  }
                  isActive={false}
                />
              </button>
              <FilterInput
                type={type}
                title={title}
                options={options}
                settings={settings}
                name={key}
                onChange={this.props.onChange}
                onClick={this.handleFilterClick(key)}
                values={this.props.filter[key]}
                isOpen={this.state.openFilter === key}
              />
            </li>
          ))}
        </ul>
        <ul className={`filter__list`}>
          {filters.map(({ icon, title, key }) => (
            <li key={key}>
              {this.props.filter[key].filter((i) => i).length > 0 && (
                <button
                  title={`Remove ${title} filter`}
                  onClick={this.handleRemoveFilter(key)}
                  className={`filter__item`}
                >
                  <Parameter icon={icon} isActive={false} />
                </button>
              )}
            </li>
          ))}
        </ul>
        <Icon
          icon={"ICON_CHEVRON"}
          scale={2}
          rotate={this.state.isOpen ? 180 : 0}
        />
      </div>
    )
  }
}

export default FilterList
