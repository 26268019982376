import React from "react"
import Match from "../Match/Match"
import "./Results.css"
import { Park } from "../../types/park"
import { getParkFeatures, stringIncludesQuery } from "../../helpers"

interface Props {
  query: string
  parks: Park[]
  hideResults: () => void
  isVisible: boolean
}

class Results extends React.PureComponent<Props> {
  render() {
    const { query, parks, hideResults, isVisible } = this.props

    return (
      <div
        className={`results ${
          query.length > 1 && isVisible ? `results--visible` : ``
        }`}
      >
        <ul className={`results__list`}>
          <li className={`results__item results__item--first`}>
            Search parks matching <strong>{query}</strong>
          </li>
          {query.length > 1 &&
            parks
              .filter(({ name }) => stringIncludesQuery(name, query))
              .map((park) => {
                const features = getParkFeatures(park)
                return (
                  <>
                    <li className={`results__item`} key={park.id}>
                      <Match query={query} park={park} features={features} isMaster />
                    </li>
                    {features.map((feature) => (
                      <li
                        className={`results__item`}
                        key={`${park.id}_${feature}`}
                      >
                        <Match
                          query={query}
                          park={park}
                          features={[feature]}
                        />
                      </li>
                    ))}
                  </>
                )
              })}
        </ul>
        <div className={`results__background`} onClick={() => hideResults()} />
      </div>
    )
  }
}

export default Results
