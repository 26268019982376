import React, { ReactNode } from "react"
import image from "../../images/logo.png";
import './simpleLayout.css'

interface ISimpleLayoutProps {
  children: ReactNode
}

export default function SimpleLayout({ children }: ISimpleLayoutProps) {
  return (
      <div className={`simple-layout`}>
          <div className={`simple-layout__content`}>
              <img src={image} alt="panattoni logo" className={'simple-layout__logo'} />
              {children}
          </div>
      </div>
  )
}
