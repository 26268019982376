import {
  ICON_AREA,
  ICON_CUT,
  ICON_DATABASE,
  ICON_FILE,
  ICON_FLAG, ICON_HEIGHT,
  ICON_PIN, ICON_TIME,
  IconsEnum
} from "./icons"
import { isAgentsMode } from "../helpers"

export enum FilterType {
  FILTER_COUNTRY = "FILTER_COUNTRY",
  FILTER_REGION = "FILTER_REGION",
  FILTER_EXISTING_AREA = "FILTER_EXISTING_AREA",
  FILTER_AVAILABLE_AREA = "FILTER_AVAILABLE_AREA",
  FILTER_FUND = "FILTER_FUND",
  FILTER_BUILDING_AVAILABLE_AREA = "FILTER_BUILDING_AVAILABLE_AREA",
  FILTER_BUILDING_STAGE = "FILTER_BUILDING_STAGE",
  FILTER_BUILDING_DELIVERY = "FILTER_BUILDING_DELIVERY",
  FILTER_BUILDING_HEIGHT = "FILTER_BUILDING_HEIGHT"
}

export enum FilterTypes {
  TYPE_RANGE = "TYPE_RANGE",
  TYPE_OPTION = "TYPE_OPTION",
  TYPE_MULTI_OPTION = "TYPE_MULTI_OPTION",
}

export interface FilterOption {
  content: string
  value: string[]
}

export interface FilterSettings {
  min?: number
  max?: number
  step?: number
  unit?: string
}

interface Filter {
  key: FilterType
  title: string
  icon: IconsEnum
  type: FilterTypes
  options?: FilterOption[]
  settings?: FilterSettings
}

export type Filters = Filter[]

const allFilters: {[key in FilterType]: Filter} = {
  [FilterType.FILTER_COUNTRY]: {
    key: FilterType.FILTER_COUNTRY,
    title: `Country`,
    icon: ICON_FLAG,
    type: FilterTypes.TYPE_OPTION,
    options: [
      { content: "CZ", value: ["CZ"] },
      { content: "SK", value: ["SK"] },
    ],
  },
  [FilterType.FILTER_REGION]: {
    key: FilterType.FILTER_REGION,
    title: `Region`,
    icon: ICON_PIN,
    type: FilterTypes.TYPE_MULTI_OPTION,
    options: [
      {
        content: "Czech republic",
        value: [
          "Hlavní město Praha",
          "Středočeský",
          "Jihočeský",
          "Plzeňský",
          "Karlovarský",
          "Ústecký",
          "Liberecký",
          "Královéhradecký",
          "Pardubický",
          "Olomoucký",
          "Moravskoslezský",
          "Jihomoravský",
          "Zlínský",
          "Kraj Vysočina",
        ],
      },
      {
        content: "Slovak republic",
        value: [
          "Bratislavský",
          "Trnavský",
          "Trenčínský",
          "Nitranský",
          "Žilinský",
          "Banskobystrický",
          "Prešovský",
          "Košický",
        ],
      },
    ],
  },
  [FilterType.FILTER_EXISTING_AREA]: {
    key: FilterType.FILTER_EXISTING_AREA,
    title: "Existing area",
    type: FilterTypes.TYPE_RANGE,
    icon: ICON_AREA,
    settings: {
      min: 0,
      max: 150000,
      step: 500
    }
  },
  [FilterType.FILTER_AVAILABLE_AREA]: {
    key: FilterType.FILTER_AVAILABLE_AREA,
    title: "Park available area ",
    type: FilterTypes.TYPE_RANGE,
    icon: ICON_CUT,
    settings: {
      min: 0,
      max: 150000,
      step: 500
    }
  },
  [FilterType.FILTER_FUND]: {
    key: FilterType.FILTER_FUND,
    title: "Fund",
    type: FilterTypes.TYPE_OPTION,
    icon: ICON_DATABASE,
    options: [
      { content: "Accolade", value: ["Accolade"] },
      { content: "AEW", value: ["AEW"] },
      { content: "Kajima", value: ["Kajima"] },
      { content: "Redside", value: ["Redside"] },
    ],
  },
  [FilterType.FILTER_BUILDING_AVAILABLE_AREA]: {
    key: FilterType.FILTER_BUILDING_AVAILABLE_AREA,
    title: "Building available area",
    type: FilterTypes.TYPE_RANGE,
    icon: ICON_AREA,
    settings: {
      min: 0,
      max: 150000,
      step: 500
    }
  },
  [FilterType.FILTER_BUILDING_STAGE]: {
    key: FilterType.FILTER_BUILDING_STAGE,
    title: "Building stage of permitting",
    type: FilterTypes.TYPE_OPTION,
    icon: ICON_FILE,
    options: [
      { content: "EIA", value: ["EIA"] },
      { content: "Planing permit", value: ["planning permit"] },
      { content: "Building permit", value: ["building permit"] },
      { content: "Existing", value: ["existing"] },
    ],
  },
  [FilterType.FILTER_BUILDING_DELIVERY]: {
    key: FilterType.FILTER_BUILDING_DELIVERY,
    title: "Building delivery",
    type: FilterTypes.TYPE_RANGE,
    icon: ICON_TIME,
    settings: {
      min: 0,
      max: 15,
      step: 3,
      unit: "months"
    }
  },
  [FilterType.FILTER_BUILDING_HEIGHT]: {
    key: FilterType.FILTER_BUILDING_HEIGHT,
    title: "Building height",
    type: FilterTypes.TYPE_RANGE,
    icon: ICON_HEIGHT,
    settings: {
      min: 10,
      max: 25,
      step: 1,
      unit: "m"
    }
  }
}

export const filters: Filters = isAgentsMode
  ? [
    allFilters.FILTER_COUNTRY,
    allFilters.FILTER_REGION,
    allFilters.FILTER_AVAILABLE_AREA,
    allFilters.FILTER_BUILDING_AVAILABLE_AREA,
    allFilters.FILTER_BUILDING_STAGE,
    allFilters.FILTER_BUILDING_DELIVERY,
    allFilters.FILTER_BUILDING_HEIGHT,
  ]
  : [
    allFilters.FILTER_COUNTRY,
    allFilters.FILTER_REGION,
    allFilters.FILTER_FUND,
    allFilters.FILTER_AVAILABLE_AREA,
    allFilters.FILTER_BUILDING_AVAILABLE_AREA,
    allFilters.FILTER_BUILDING_DELIVERY,
    allFilters.FILTER_BUILDING_HEIGHT,
  ]
