import React from "react"
import "./Icon.css"
import { iconPaths, IconsEnum } from "../../constants/icons"
import { cls } from "../../helpers"

interface Props {
  icon: IconsEnum
  caption?: string
  scale?: number
  rotate?: number
}

const threshold = 10

class Icon extends React.PureComponent<Props> {
  render() {
    const { icon, caption, scale, rotate } = this.props

    return (
      <div
        style={{ ["--scale" as any]: scale, ["--rotate" as any]: `${rotate || 0}deg` }}
        className={cls(
          "icon",
          caption && caption.length > threshold ? `icon--horizontal` : false
        )}
      >
        <div className={`icon__image`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 50 50`}>
            <path d={iconPaths[icon]} />
          </svg>
        </div>
        <div className={`icon__caption`}>{caption}</div>
      </div>
    )
  }
}

export default Icon
