import React from "react"
import "./AdminLink.css"
import Icon from "../Icon/Icon"
import { ICON_USER } from "../../constants/icons"

class AdminLink extends React.PureComponent {
  render() {
    return (
      <a
        className={`admin-link`}
        href={`http://panattoni.visualio.cz`}
        target={`_blank`}
      >
        <span>Admininistration</span>
        <Icon icon={ICON_USER} />
      </a>
    )
  }
}

export default AdminLink
