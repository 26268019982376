import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "./vars.css"
import App from "./containers/App/App"
import { BrowserRouter as Router } from "react-router-dom"
import * as serviceWorker from "./serviceWorker"
// ie polyfills
import "mdn-polyfills/Array.prototype.includes"
import "react-app-polyfill/ie11"
import "mdn-polyfills/String.prototype.repeat"
import "mdn-polyfills/String.prototype.startsWith"
import "intersection-observer"
import "unorm"

export const appRoot: HTMLElement | null = document.getElementById("root")

ReactDOM.render(
  // use router here to pass history prop to App
  <Router>
    <App />
  </Router>,
  appRoot
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
