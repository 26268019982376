import {ICON_360, ICON_GALLERY, ICON_PIN} from "./icons";

export type ParkFeatureEnum =
    | "FEATURE_360"
    | "FEATURE_MAP"
    | "FEATURE_GALLERY"

export const FEATURE_360: ParkFeatureEnum = `FEATURE_360`
export const FEATURE_MAP: ParkFeatureEnum = `FEATURE_MAP`
export const FEATURE_GALLERY: ParkFeatureEnum = `FEATURE_GALLERY`

export const parkFeatures = {
    [FEATURE_360]: {
        title: `Open 360° view`,
        help: `360° view`,
        icon: ICON_360,
        link: (id?: number) => `/${id}/panorama`
    },
    [FEATURE_MAP]: {
        title: `Show in Map`,
        help: `Map`,
        icon: ICON_PIN,
        link: (id?: number) => `/${id}/map`
    },
    [FEATURE_GALLERY]: {
        title: `Show gallery`,
        help: `Gallery`,
        icon: ICON_GALLERY,
        link: (id?: number) => `/${id}#gallery`
    }
}