import config from "../config"
import { METHOD_POST, MethodEnum } from "../constants/restMethods"
import { Park } from "../types/park"
import {
  FEATURE_360,
  FEATURE_GALLERY,
  FEATURE_MAP,
  ParkFeatureEnum,
} from "../constants/parkFeatures"

interface Request {
  url: string
  body?: object
  method?: MethodEnum
}

export function performRequest<T>({
  url,
  body,
  method = `GET`,
}: Request): Promise<T> {
  return fetch(`${config.endpoint}/${url}`, {
    method,
    body: canMethodHaveBody(method) ? JSON.stringify(body) : null,
  })
    .then((r) => processResponse(r))
    .catch((e) => processError(e))
}

function processResponse(response: Response) {
  if (response.status < 400) return response.json()
  throw new Error(`GENERAL_ERROR`)
}

function processError(error: Error) {
  throw new Error(error.message)
}

function canMethodHaveBody(method: MethodEnum) {
  return method === METHOD_POST || method === METHOD_POST
}

export function getParkFeatures(park: Park): ParkFeatureEnum[] {
  const features: ParkFeatureEnum[] = []

  if (park.gallery.length > 0) features.push(FEATURE_GALLERY)
  if (park.links.panorama.length > 0) features.push(FEATURE_360)
  if (park.links.map) features.push(FEATURE_MAP)

  return features
}

export function cls(...args: (string | false | undefined)[]) {
  return args.filter((i) => i).join(` `)
}

export function getYoutubeId(url: string): string {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[2].length === 11 ? match[2] : ``
}

export function intersect(
  a: Array<string | number>,
  b: Array<string | number>
): Array<string | number> {
  const s = new Set(b)
  return a.filter((x) => s.has(x))
}

export function stringIncludesQuery(string: string, query: string): boolean {
  const normalizedString = normalizeString(string)
  const normalizedQuery = normalizeString(query)
  return normalizedString.includes(normalizedQuery)
}

export function normalizeString(string: string): string {
  return string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, ``)
    .toLowerCase()
}

export function isDesktop() {
  const isClient = typeof window === "object"
  return isClient ? window.innerWidth > 1280 : false
}

export function isLogged() {
  if (isAgentsMode) return true
  return localStorage.getItem("loginInfo") === "true"
}

export const isAgentsMode: boolean = process.env["REACT_APP_MODE"] === "agents"

export function replaceArray<T>(arr: T[], index: number, item: T): T[] {
  return arr.map((it, idx) => (index === idx ? item : it))
}

export function toggleArray<T>(arr: T[], item: T): T[] {
  return arr.includes(item) ? arr.filter((i) => i !== item) : arr.concat(item)
}

export function stringToNumber(value: string | number): number {
  if (typeof value === "string") return parseFloat(value)
  return value
}
