import React from "react"
import './narrowButton.css'

interface INarrowButtonProps {
  text: string
}

export default function NarrowButton({ text }: INarrowButtonProps) {
  return <span className={"narrow-button"}>{text}</span>
}
