import React from "react"
import { Link } from "react-router-dom"
import image from "../../images/logo.png"
import "./Logo.css"

class Logo extends React.PureComponent {
  render() {
    return (
      <Link to="/" className={`logo`}>
        <img src={image} alt="Panattoni logo" />
      </Link>
    )
  }
}

export default Logo
