import React from "react"
import Search from "../Search/Search"
import FilterList from "../FilterList/FilterList"
import AdminLink from "../AdminLink/AdminLink"
import "./Header.css"
import Hamburger from "../Hamburger/Hamburger"
import { FilterType } from "../../constants/filters"
import { isAgentsMode } from "../../helpers"

interface Props {
  query: string
  setQuery: (e: React.FormEvent<HTMLInputElement>) => void
  filter: {
    [key in FilterType]: string[]
  }
  isOpen: boolean
  toggleNav: () => void
  onFilterChange?: (filter: FilterType, value: string, index?: number) => void
  onFilterReset: (filter: FilterType) => void
}

export default function Header({
  query,
  setQuery,
  filter,
  toggleNav,
  isOpen,
  onFilterChange,
  onFilterReset,
}: Props) {
  return (
    <header className={`header`}>
      <button className={`header__hamburger`} onClick={toggleNav}>
        <Hamburger isActive={isOpen} />
      </button>
      <div />
      <Search query={query} setQuery={setQuery} />
      {onFilterChange && (
        <FilterList
          filter={filter}
          onChange={onFilterChange}
          onReset={onFilterReset}
        />
      )}
      {isAgentsMode ? null : <AdminLink />}
    </header>
  )
}
