import React from "react"
import "./Match.css"
import Flagger from "../Flagger/Flagger"
import Link from "../Link/Link"
import { Park } from "../../types/park"
import { ParkFeatureEnum, parkFeatures } from "../../constants/parkFeatures"
import Parameter from "../Parameter/Parameter"

interface Props {
  query: string
  park: Park
  features: ParkFeatureEnum[]
  isMaster?: boolean
}

class Match extends React.Component<Props> {
  render() {
    const {
      query,
      park,
      isMaster,
      features: [mainFeature, ...moreFeatures],
    } = this.props

    const link = isMaster
      ? `/${park.id}`
      : parkFeatures[mainFeature].link(park.id)

    return link ? (
      <Link to={link} className={`match`}>
        <span className={`match__title`}>
          <Flagger string={park.name} query={query} />
        </span>

        <span className={`match__description`}>
          {isMaster ? `Open detail` : parkFeatures[mainFeature].title}
        </span>

        <div className={`match__parameters`}>
          {[mainFeature, ...moreFeatures].map((feature) => (
            <Parameter
              key={feature}
              icon={parkFeatures[feature].icon}
              isActive={false}
            />
          ))}
        </div>
      </Link>
    ) : null
  }
}

export default Match
