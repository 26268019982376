import React from "react"
import "./Headline.css"

interface Props {
  children: React.ReactNode
}

class Headline extends React.PureComponent<Props> {
  render() {
    return <div className={`headline`}>{this.props.children}</div>
  }
}

export default Headline
