import React from "react"
import { normalizeString } from "../../helpers"

interface Props {
  string: string
  query: string
}

export default function Flagger({ string, query }: Props) {
  const regexp = new RegExp(`(${query})`, `ig`)

  return (
    <>
      {string
        .split(regexp)
        .map((chunk, idx) =>
          normalizeString(chunk) === normalizeString(query) ? (
            <strong key={idx}>{chunk}</strong>
          ) : (
            <span key={idx}>{chunk}</span>
          )
        )}
    </>
  )
}
