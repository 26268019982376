import React from "react"
import "./Search.css"

interface Props {
  query: string
  setQuery: (e: React.FormEvent<HTMLInputElement>) => void
}

export default function Search({ query, setQuery }: Props) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  return (
    <form role="search" onSubmit={(e) => handleSubmit(e)} className={`search`}>
      <input
        className={`search__input`}
        type="text"
        value={query}
        onChange={(e) => setQuery(e)}
        placeholder={`Search...`}
      />
    </form>
  )
}
