import React from 'react';
import NarrowButton from "../NarrowButton/NarrowButton";
import {Link} from "react-router-dom";
import config from "../../config";
import './signpostPage.css'

export default function SignpostPage() {
    return (
        <div className={'signpost-page'}>
            <Link to={config.routes.homepage()}>
                <NarrowButton text={'Show Panattoni parks'} />
            </Link>
            <a href={'http://panattoni.visualio.cz/admin/generate-pdf/'}>
                <NarrowButton text={'Create PDF presentation'} />
            </a>
            <a href={'https://stash.visualio.cz/nextcloud/index.php/s/8bsSeCMiaqPXPXB/'}>
                <NarrowButton text={'Go to shared drive'} />
            </a>
        </div>
    );
}