import React, { Component } from "react"
import "./loginPage.css"
import { Redirect } from "react-router-dom";
import config from "../../config"
import NarrowButton from "../../components/NarrowButton/NarrowButton";
import {isLogged} from "../../helpers";

type LoginPagePropsType = {
  loginHandle: () => void
}

type LoginPageState = {
  passInput: string
  passError: ""
}

class LoginPage extends Component<LoginPagePropsType> {
  state: LoginPageState = {
    passInput: "",
    passError: "",
  }

  login = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (this.state.passInput === config.password) {
      this.setState({ ...this.state, passError: "" })
      this.props.loginHandle()
    } else {
      this.setState({ ...this.state, passError: "Incorrect password" })
    }
  }

  handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ passInput: e.currentTarget.value })
  }

  render() {
    return (
        <>
          {isLogged() && <Redirect to={config.routes.homepage()} />}
          <form className={`login-page`} onSubmit={() => this.login}>
            <input
                onChange={this.handleInput}
                type="text"
                className={`login-page__input`}
                value={this.state.passInput}
                placeholder={`Password`}
            />
            <button type={"submit"} onClick={this.login} className={'login-page__button'}>
              <NarrowButton text={'Login'} />
            </button>
            {this.state.passError && (
                <span className={`login-page__input__error`}>
              {this.state.passError}
            </span>
            )}
          </form>
        </>
    )
  }
}

export default LoginPage
