export const STATUS_DEFAULT: StatusEnum = `STATUS_DEFAULT`
export const STATUS_LOAD: StatusEnum = `STATUS_LOAD`
export const STATUS_SUCCESS: StatusEnum = `STATUS_SUCCESS`
export const STATUS_FAIL: StatusEnum = `STATUS_FAIL`

export type StatusEnum =
  | "STATUS_DEFAULT"
  | "STATUS_LOAD"
  | "STATUS_SUCCESS"
  | "STATUS_FAIL"
