import React, { ChangeEvent, useState } from "react"
import "./FilterInput.css"
import {
  FilterType,
  FilterOption,
  FilterTypes,
  FilterSettings,
} from "../../constants/filters"
import { cls } from "../../helpers"
import FilterSummary from "../FilterSummary/FilterSummary"
import MultiRangeSlider from "../MultiRangeSlider/MultiRangeSlider"

interface IFilterInputProps {
  type: FilterTypes
  title: string
  options?: FilterOption[]
  name: FilterType
  onChange: (filter: FilterType, value: string, index?: number) => void
  onClick: () => void
  values: string[]
  isOpen: boolean
  settings?: FilterSettings
}

function FilterCheckBox({
  value,
  content,
  name,
  onChange,
  checked,
}: {
  name: FilterType
  value: string
  content: string
  onChange: (filter: FilterType, value: string, index?: number) => void
  checked: boolean
}) {
  return (
    <label className={`filter-input__checkbox`}>
      <input
        type="checkbox"
        name={`${name}[]`}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(name, e.target.value)
        }
        checked={checked}
      />
      <span>{content}</span>
    </label>
  )
}

export default function FilterInput({
  name,
  type,
  title,
  options = [],
  onChange,
  onClick,
  values,
  isOpen,
  settings,
}: IFilterInputProps) {
  const [activeGroup, setActiveGroup] = useState<string | null>(null)

  const filterValues = values.filter((i) => i)

  const handleLegendClick = (key: string) => {
    return () => setActiveGroup(activeGroup === key ? null : key)
  }

  const min = settings?.min || 0
  const max = settings?.max || 150000

  return (
    <fieldset className={`filter-input`}>
      <FilterSummary
        className={"filter-input__title"}
        title={title}
        count={filterValues.length}
        isActive={isOpen}
        onClick={onClick}
      />
      {isOpen && (
        <div className={"filter-input__body"}>
          {type === FilterTypes.TYPE_RANGE ? (
            <MultiRangeSlider
              onValueChange={(value: number, index: number) =>
                onChange(name, value.toString(), index)
              }
              values={[
                values[0] ? parseInt(values[0]) : min,
                values[1] ? parseInt(values[1]) : max,
              ]}
              min={min}
              max={max}
              step={settings?.step || 1}
              unit={
                settings?.unit || (
                  <span>
                    m<sup>2</sup>
                  </span>
                )
              }
            />
          ) : type === FilterTypes.TYPE_OPTION ? (
            options.map(({ content, value: [value] }) => (
              <FilterCheckBox
                key={content}
                name={name}
                value={value}
                content={content}
                onChange={onChange}
                checked={values.includes(value)}
              />
            ))
          ) : type === FilterTypes.TYPE_MULTI_OPTION ? (
            options.map(({ content, value: multiValues }) => {
              const groupValues = values.filter((val) =>
                multiValues.includes(val)
              )
              return (
                <fieldset
                  key={content}
                  className={cls(
                    `filter-input__multi`,
                    content === activeGroup
                      ? "filter-input__multi--active"
                      : false
                  )}
                >
                  <FilterSummary
                    title={content}
                    count={groupValues.length}
                    isActive={content === activeGroup}
                    onClick={handleLegendClick(content)}
                  />
                  <div>
                    {multiValues.map((item) => (
                      <FilterCheckBox
                        key={item}
                        name={name}
                        value={item}
                        content={item}
                        onChange={onChange}
                        checked={groupValues.includes(item)}
                      />
                    ))}
                  </div>
                </fieldset>
              )
            })
          ) : null}
        </div>
      )}
    </fieldset>
  )
}
