export const ICON_HOME: IconsEnum = "ICON_HOME"
export const ICON_360: IconsEnum = `ICON_360`
export const ICON_PIN: IconsEnum = `ICON_PIN`
export const ICON_AR: IconsEnum = `ICON_AR`
export const ICON_ACCOLADE: IconsEnum = `ICON_ACCOLADE`
export const ICON_GALLERY: IconsEnum = `ICON_GALLERY`
export const ICON_FOLDER: IconsEnum = `ICON_FOLDER`
export const ICON_USER: IconsEnum = `ICON_USER`
export const ICON_AREA: IconsEnum = `ICON_AREA`
export const ICON_CUT: IconsEnum = `ICON_CUT`
export const ICON_DATABASE: IconsEnum = `ICON_DATABASE`
export const ICON_FILE: IconsEnum = `ICON_FILE`
export const ICON_FLAG: IconsEnum = `ICON_FLAG`
export const ICON_CHEVRON: IconsEnum = `ICON_CHEVRON`
export const ICON_PLUS: IconsEnum = `ICON_PLUS`
export const ICON_MINUS: IconsEnum = `ICON_MINUS`
export const ICON_HEIGHT: IconsEnum = `ICON_HEIGHT`
export const ICON_TIME: IconsEnum = `ICON_TIME`

export const iconPaths = {
  [ICON_PIN]: `M24.94 30.023c-5.73-.033-10.348-4.706-10.313-10.438.033-5.73 4.706-10.348 10.438-10.313 5.729.034 10.347 4.708 10.316 10.438-.036 5.73-4.708 10.349-10.441 10.313M25.118.141C14.116.077 5.144 8.942 5.08 19.943a19.81 19.81 0 0 0 3.49 11.364l4.879 5.767 11.375 12.785 9.462-10.386 1.474-1.632 5.54-6.348a19.811 19.811 0 0 0 3.62-11.315C44.985 9.179 36.119.209 25.118.141`,
  [ICON_GALLERY]: `M4.861 10.624h40.273v28.752H4.861V10.624zM.5 43.74h49V6.26H.5v37.48zm16.971-25.744a3.796 3.796 0 1 0-7.591 0 3.796 3.796 0 0 0 7.591 0m27.49 22.122L38.15 25.123l-2.481 2.298-7.071-5.629-4.713 12.594-5.627-5.73L8.179 40.118h36.782z`,
  [ICON_FOLDER]: `M8.27 11.489a3.14 3.14 0 0 0-3.142 3.145V38.913a2.314 2.314 0 0 1-2.313 2.317 2.32 2.32 0 0 1-2.314-2.317V7.445A3.128 3.128 0 0 1 3.19 4.346c.152-.028.298-.034.447-.034h13.526c3.285 0 5.856.5 7.794 2.404 1.539 1.513 4.761 1.479 4.761 1.479h10.08c1.334 0 2.4 1.402 2.4 3.139v.155H8.27zm40.021 5.707a3.2 3.2 0 0 0-1.93-.651H13.478a3.144 3.144 0 0 0-3.142 3.145v22.866a3.14 3.14 0 0 0 3.142 3.133h32.884a3.13 3.13 0 0 0 3.139-3.133V19.681a3.162 3.162 0 0 0-1.21-2.485`,
  [ICON_AR]: `M25.404 32.388a7.287 7.287 0 0 1-7.283-7.285 7.284 7.284 0 1 1 7.283 7.285m14.308-15.236C31.81 9.25 19 9.25 11.098 17.152l-7.95 7.95 7.95 7.947c7.902 7.903 20.712 7.903 28.614 0l7.95-7.947-7.95-7.95zM36.92.5v2.516h10.063v10.062H49.5V.5H36.92zm10.063 36.423v10.059H36.92V49.5h12.577V36.923h-2.514zM.5.5v12.578h2.517V3.016h10.062V.5H.5zm0 36.423V49.5h12.579v-2.519H3.017V36.923H.5z`,
  [ICON_360]: `M25.519 14.258a6.124 6.124 0 0 0-3.298.959 1.832 1.832 0 0 1-.21 3.649 1.83 1.83 0 0 1-1.806-1.54 6.134 6.134 0 0 0 5.312 9.202 6.134 6.134 0 1 0 .002-12.27m-6.333 18.631c-.459-.455-1.141-.061-1.141.474v2.188C9.93 34.398 4.113 31.108 4.113 25.853c0-2.195 1.017-4.252 2.79-6.026v-2.214C2.7 20.184.125 23.653.125 27.469c0 6.542 7.566 12.06 17.92 13.796v2.825c0 .531.683.927 1.141.471l5.323-5.336c.259-.26.248-.715 0-.98l-5.323-5.356zm23.909-15.277v2.214c1.773 1.774 2.789 3.832 2.789 6.026 0 5.136-5.553 8.392-13.378 9.615v5.702c10.067-1.838 17.37-7.277 17.37-13.701 0-3.816-2.579-7.285-6.781-9.856m-17.576-5.887a8.663 8.663 0 0 1 8.663 8.665 8.663 8.663 0 0 1-8.663 8.661 8.661 8.661 0 0 1-8.663-8.661 8.663 8.663 0 0 1 8.663-8.665m12.893-2.759h-7.453c-.349-.695-2.06-4.155-2.978-3.682h-5.738c-.913-.473-2.626 2.987-2.979 3.682h-7.678a2.308 2.308 0 0 0-2.308 2.309v16.177a2.31 2.31 0 0 0 2.308 2.309h8.483a10.695 10.695 0 0 0 5.448 1.485c1.99 0 3.854-.543 5.451-1.485h7.442a2.311 2.311 0 0 0 2.308-2.309V11.275a2.307 2.307 0 0 0-2.306-2.309`,
  [ICON_ACCOLADE]: `M36.097 15.196h-6.704c-.267-.886-.601-1.725-.967-2.358L21.951 1.624c-.862-1.499-2.279-1.499-3.148 0l-6.47 11.214c-.869 1.498-1.575 4.14-1.575 5.872v12.954a3.154 3.154 0 0 0 3.147 3.145h6.702c.27.887.6 1.724.963 2.354l6.477 11.214c.864 1.498 2.281 1.498 3.146 0l6.475-11.214c.866-1.497 1.575-4.143 1.575-5.874V18.343a3.156 3.156 0 0 0-3.146-3.147`,
  [ICON_HOME]: `M49.063 22.826L26.033 1.892a1.531 1.531 0 0 0-2.065 0L.939 22.826c-1.037.943-.373 2.67 1.032 2.67h6.156v21.477c0 .847.686 1.535 1.532 1.535h30.679c.847 0 1.535-.688 1.535-1.535V25.497h6.159c1.4 0 2.066-1.728 1.031-2.671`,
  [ICON_USER]: `M10.505 14.863c0-7.999 6.487-14.486 14.486-14.486s14.486 6.487 14.486 14.486c0 8.002-6.487 14.486-14.486 14.486s-14.486-6.484-14.486-14.486m35.344 34.76v-12.61c-.544-7.025-6.909-8.451-6.909-8.451-5.051 7.024-13.881 7.024-13.881 7.024-9.158-.55-13.883-7.024-13.883-7.024-6.586 2.195-7.025 8.341-7.025 8.341v12.721h41.698z`,
  [ICON_AREA]: `M4.1 45.9h41.8V4.2H4.1V45.9zM50 49.9H0V0.1h49.9L50 49.9zM22.2 33.7h-3.5v-7.3c0-0.7-0.1-1.4-0.5-2.1 -0.3-0.4-0.9-0.7-1.4-0.7 -0.8-0.1-1.5 0.3-1.9 0.9 -0.5 1-0.7 2.1-0.6 3.2v5.9h-3.3V21.2h2.6l0.5 1.6h0.2c0.4-0.6 0.9-1 1.5-1.4 0.7-0.3 1.4-0.5 2.2-0.5 1.5-0.2 3 0.5 3.8 1.8h0.3c0.4-0.6 0.9-1.1 1.5-1.4 0.7-0.3 1.4-0.5 2.2-0.5 1.2-0.1 2.4 0.3 3.2 1.1 0.8 1 1.2 2.2 1.1 3.5v8.2h-3.5v-7.3c0-0.7-0.1-1.4-0.5-2.1 -0.3-0.4-0.9-0.7-1.4-0.7 -0.7-0.1-1.4 0.3-1.9 0.9 -0.5 0.9-0.7 1.9-0.6 2.9L22.2 33.7zM38.9 26.1h-6.6v-1.4l2.4-2.4c0.5-0.5 1-1 1.4-1.5 0.2-0.2 0.4-0.5 0.5-0.8 0.1-0.3 0.1-0.5 0-0.8 0-0.3-0.1-0.6-0.3-0.8 -0.2-0.2-0.5-0.3-0.8-0.3 -0.4 0-0.7 0.1-1.1 0.2 -0.4 0.2-0.8 0.4-1.1 0.7l-1.1-1.3c0.4-0.3 0.7-0.6 1.1-0.8 0.3-0.2 0.7-0.3 1-0.4 0.4-0.1 0.8-0.1 1.2 0 0.5 0 1.1 0.1 1.6 0.3 0.4 0.2 0.8 0.5 1.1 0.9 0.3 0.4 0.4 0.9 0.4 1.3 0 0.4-0.1 0.9-0.2 1.3 -0.2 0.4-0.4 0.8-0.7 1.2 -0.5 0.6-1.1 1.2-1.7 1.7l-1.2 1.2 0 0h4.2V26.1z`,
  [ICON_CUT]: `M23.7 23.8h-2.1v2.5h4.6v-4.6h-2.5V23.8zM10.7 23.8h5.4v2.5h-5.4V23.8zM23.7 10.8h2.5v5.4h-2.5V10.8zM45.9 46H4V26.3h1.3v-2.5H4V4.1h19.7v1.3h2.5V4.1h19.6V46zM0 50h50V0H0V50z`,
  [ICON_DATABASE]: `M32.1 8.5c-1.1 0.6-2.3 1-3.5 1.4 -4.2 1.2-8.5 1.7-12.8 1.6 -4.1 0.1-8.3-0.4-12.3-1.5C2.3 9.7 1.1 9.3 0 8.7c0 1.6 0 3.2 0 4.9 0.8 0.5 1.7 0.9 2.5 1.3 4.2 1.3 8.6 2.1 13 2.2 4 0 8-0.5 11.9-1.5 3.5-1 4.1-2.1 4.1-2.3 0-0.2 0-0.4 0.2-0.6L32.1 8.5zM0.2 4.7c0 0.3 1 1.4 4.3 2.4 3.7 1 7.5 1.5 11.4 1.4 4 0.1 8-0.5 11.9-1.5 3.5-1 4.1-2.1 4.1-2.3 0-0.2-0.7-1.2-4.1-2.3 -3.9-1.1-7.9-1.6-11.9-1.5C12 0.8 8.1 1.4 4.3 2.5 0.8 3.4 0.3 4.4 0.3 4.7l0 0M50 40.7c-1 0.5-2.1 1-3.3 1.3 -4.2 1.2-8.5 1.7-12.8 1.6 -3.9 0-7.8-0.4-11.6-1.3 -1.3-0.3-2.6-0.8-3.9-1.4v4.5l0 0c0 0.2 0.7 1.3 4 2.3 3.8 1.1 7.8 1.6 11.7 1.5 4 0.1 8-0.5 11.8-1.5 3.3-1 4-2.1 4-2.3l0 0L50 40.7zM32.1 17.2c-1.1 0.6-2.3 1-3.5 1.3 -4.2 1.2-8.5 1.8-12.8 1.6 -5.3-0.1-10.5-1.1-15.5-3v4.9c3.5 1.9 7.3 3.1 11.3 3.5 1.4 0 2.8 0 4.2 0h0.2c2.5-2.8 9.4-4.2 16-4.4L32.1 17.2zM46.6 33.4c-4.1 1.1-8.4 1.6-12.6 1.6 -4.2 0.1-8.3-0.5-12.3-1.5 -1.2-0.3-2.4-0.7-3.5-1.3v3.5 1.5c1.2 1.5 6.9 3.5 15.6 3.5 4.1 0.1 8.3-0.5 12.2-1.6C49.4 38 50 36.9 50 36.7c0-0.2 0-0.3 0-0.5l0 0v-4.1c-1.1 0.6-2.3 1-3.5 1.3M22.5 30.6c3.7 0.9 7.6 1.4 11.4 1.4 4 0.1 7.9-0.4 11.8-1.5 3.5-1 4.2-2.1 4.2-2.3l0 0c0-0.2-0.8-1.3-4.1-2.3 -3.9-1.1-7.9-1.6-11.9-1.5h-0.3c-3.7 0-7.4 0.4-11 1.3 -3.2 0.9-4.3 1.9-4.4 2.4l0 0c0 0.3 1 1.4 4.3 2.4M15.2 28.7c-3.9 0.1-7.8-0.4-11.5-1.5 -1.2-0.3-2.4-0.7-3.5-1.3v4.9c0.8 0.5 1.7 0.9 2.5 1.3 4 1.3 8.1 2.1 12.3 2.2L15.2 28.7zM15.2 37.4c-3.9 0.1-7.8-0.4-11.5-1.5 -1.2-0.3-2.4-0.7-3.5-1.3v4.8c0.8 0.5 1.7 0.9 2.5 1.3 4 1.3 8.1 2.1 12.3 2.2L15.2 37.4z`,
  [ICON_FILE]: `M33.8 0v10.4h10.3L33.8 0zM40.2 23.4H9.8v-3.8h30.4V23.4zM40.2 36.2l-8.7 8.6 -0.2-0.3 -6-5.9 2.8-2.8 3.4 3.4 6.5-6.5 2.8 2.8L40.2 36.2zM9.8 26.4h22v3.8H9.8V26.4zM9.8 13H25v3.8H9.8L9.8 13zM30 0H5.9V50h38.3V14.3H29.9L30 0z`,
  [ICON_FLAG]: `M11.6 1.1c-0.4-0.1-0.8-0.1-1.2 0C9.5 1.6 9 2.6 9.3 3.6v44.8C9.3 49.3 10 50 10.8 50c0.8 0 0.8-0.7 0.8-1.5V1.1zM40 2.4c-0.6-0.2-1.2-0.1-1.6 0.3 -0.3 0.2-5.2 4.3-12.9-0.2 -3.5-2.3-7.7-3-11.8-2.1v18.8c3.5-1.1 7.2-0.5 10.2 1.6 2.5 1.6 5.5 2.5 8.5 2.6 1.1 0 2.2-0.1 3.3-0.4 1.7-0.4 3.3-1.2 4.7-2.3 0.3-0.3 0.5-0.7 0.5-1.1V3.8c0-0.6-0.4-1.2-0.9-1.4`,
  [ICON_CHEVRON]: `M 29,21.5 30.5,23 25,28.5 19.5,23 21,21.5 25,25.6  z`,
  [ICON_PLUS]: `M 24.2,21.5 24.2,24.2 21.5,24.2 21.5,25.8 24.2,25.8 24.2,28.5 25.8,28.5 25.8,25.8 28.5,25.8   28.5,24.2 25.8,24.2 25.8,21.5 z`,
  [ICON_MINUS]: `M21.5 24.2h7v1.5h-7z`,
  [ICON_TIME]: `M25,0A25,25,0,1,0,50,25,25,25,0,0,0,25,0ZM35.2,26H24.6a1.2,1.2,0,0,1-.5-.5.6.6,0,0,1-.1-.4V14.8h0a1,1,0,1,1,2,0h0V24h9.2a1,1,0,0,1,0,2Z`,
  [ICON_HEIGHT]: `M0,10.2V39.6H7.6V20.8H18.8V39.6H50V10.2ZM47.2,34.8l-3,3-2.8-3h2.4V14.7H41.4l2.8-2.8,3,2.8H44.7V34.8Z`,
}

export type IconsEnum =
  | "ICON_HOME"
  | "ICON_360"
  | "ICON_PIN"
  | "ICON_AR"
  | "ICON_ACCOLADE"
  | "ICON_GALLERY"
  | "ICON_USER"
  | "ICON_FOLDER"
  | "ICON_AREA"
  | "ICON_CUT"
  | "ICON_DATABASE"
  | "ICON_FILE"
  | "ICON_FLAG"
  | "ICON_CHEVRON"
  | "ICON_PLUS"
  | "ICON_MINUS"
  | "ICON_HEIGHT"
  | "ICON_TIME"
