import React from "react"
import { HashLink as RouterLink } from "react-router-hash-link"

interface Props {
  to?: string
  className?: string
  title?: string
  children: React.ReactNode
}

export default function Link({ to, className, children, title }: Props) {
  if (!to)
    return (
      <div title={title} className={className}>
        {children}
      </div>
    )
  if (to.startsWith(`http`))
    return (
      <a href={to} title={title} className={className} target={`_blank`}>
        {children}
      </a>
    )
  return (
    <RouterLink
      to={to}
      className={className}
      title={title}
      scroll={(el) =>
        el.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    >
      {children}
    </RouterLink>
  )
}
