import React, { Component, lazy, Suspense } from "react"
import NavBar from "../../components/NavBar/NavBar"
import Header from "../../components/Header/Header"
import Headline from "../../components/Headline/Headline"
import Results from "../../components/Results/Results"
import Main from "../../components/Main/Main"
import Logo from "../../components/Logo/Logo"
import Link from "../../components/Link/Link"
import config from "../../config"
import getParks from "../../api/panattoni"
import { Redirect, Route, Switch, withRouter } from "react-router-dom"
import { Park } from "../../types/park"
import {
  STATUS_DEFAULT,
  STATUS_FAIL,
  STATUS_LOAD,
  STATUS_SUCCESS,
  StatusEnum,
} from "../../constants/statuses"
import { FilterType } from "../../constants/filters"
import LoginPage from "../LoginPage/LoginPage"
import "./app.css"
import {
  replaceArray,
  isDesktop,
  isLogged,
  toggleArray,
  isAgentsMode,
} from "../../helpers"
import SimpleLayout from "../SimpleLayout/SimpleLayout"
import SignpostPage from "../../components/SignpostPage/SignpostPage"

const Homepage = lazy(() => import(`../Homepage/Homepage`))
const PanoramaParks = lazy(() => import(`../PanoramaParks/PanoramaParks`))
const Detail = lazy(() => import(`../Detail/Detail`))
const Panorama = lazy(() => import(`../Panorama/Panorama`))
const Map = lazy(() => import(`../Map/Map`))
const Iframe = lazy(() => import(`../Iframe/Iframe`))

export interface State {
  parks: Park[]
  query: string
  isNavOpen: boolean
  parksFetchStatus: StatusEnum
  filter: {
    [key in FilterType]: string[]
  }
  hasError: boolean
  areResultsVisible: boolean
  isLoggedIn: boolean
}

const defaultState = {
  parks: [],
  parksFetchStatus: STATUS_DEFAULT,
  isNavOpen: false,
  query: ``,
  filter: {
    [FilterType.FILTER_FUND]: [],
    [FilterType.FILTER_AVAILABLE_AREA]: ["", ""],
    [FilterType.FILTER_EXISTING_AREA]: ["", ""],
    [FilterType.FILTER_COUNTRY]: [],
    [FilterType.FILTER_REGION]: [],
    [FilterType.FILTER_BUILDING_AVAILABLE_AREA]: ["", ""],
    [FilterType.FILTER_BUILDING_STAGE]: [],
    [FilterType.FILTER_BUILDING_HEIGHT]: ["", ""],
    [FilterType.FILTER_BUILDING_DELIVERY]: ["", ""],
  },
  hasError: false,
  areResultsVisible: false,
  isLoggedIn: false,
}

class App extends Component<any> {
  state: State = defaultState

  /* lifecycle hooks */
  componentDidMount() {
    this.fetchParks()
    if (isLogged()) {
      this.setState({ ...this.state, isLoggedIn: true })
    } else {
      this.props.history.push("/login")
    }
  }

  componentDidUpdate(prevProps: any) {
    // normalize state on location change
    if (prevProps.location.pathname !== this.props.location.pathname)
      this.setState({ isNavOpen: false, areResultsVisible: false })
    if (this.isPanoramaOrMap() && !this.state.isNavOpen)
      this.setState({ isNavOpen: true })
    // scroll to hash
    if (prevProps.location.hash !== this.props.location.hash) {
      const id = this.props.location.hash.replace("#", "")
      const element = document.getElementById(id)
      element &&
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        })
    }
  }

  componentDidCatch(e: Error) {
    this.setState({ hasError: true })
  }

  /* async */
  fetchParks = async (): Promise<void> => {
    try {
      this.setState({ parksFetchStatus: STATUS_LOAD })
      const parks = await getParks()
      this.setState({
        parks: isAgentsMode
          ? parks.filter((park) =>
              park.buildings?.some((building) =>
                building.available_space ? building.available_space > 0 : false
              )
            )
          : parks,
        parksFetchStatus: STATUS_SUCCESS,
      })
    } catch (e) {
      console.error(e)
      this.setState({
        ...this.state,
        parksFetchStatus: STATUS_FAIL,
      })
    }
  }

  /* handlers */
  handleSetQuery = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      query: e.currentTarget ? e.currentTarget.value : ``,
      areResultsVisible: true,
    })
  }

  handleFilterChange = (filter: FilterType, value: string, index?: number) => {
    this.setState((prevState: State) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [filter]:
          index !== undefined
            ? replaceArray(prevState.filter[filter], index, value) // set at index
            : toggleArray(prevState.filter[filter], value), // toggle
      },
    }))
  }

  handleFilterReset = (filter: FilterType) => {
    this.setState((prevState: State) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [filter]: defaultState.filter[filter],
      },
    }))
  }

  handleToggleNavBar = () => {
    if (this.isPanoramaOrMap()) {
      this.props.history.push(this.getDetailLink())
    } else {
      this.setState((prevState: State) => ({
        ...prevState,
        isNavOpen: !prevState.isNavOpen,
      }))
    }
  }

  closeNavBar = () => {
    this.setState((prevState: State) => ({
      ...prevState,
      isNavOpen: false,
    }))
  }

  handleHideResults = () => {
    this.setState({ areResultsVisible: false })
  }

  loginHandle = () => {
    this.setState({ ...this.state, isLoggedIn: true })
    localStorage.setItem("loginInfo", "true")
    this.props.history.push(config.routes.signpost())
  }

  /* render */
  render() {
    return (
      <Switch>
        <Route path={[config.routes.login(), config.routes.signpost()]} exact>
          {isAgentsMode && <Redirect to={config.routes.homepage()} />}
          <SimpleLayout>
            <Route path={config.routes.login()} exact>
              <LoginPage loginHandle={this.loginHandle} />
            </Route>
            <Route path={config.routes.signpost()} exact>
              <SignpostPage />
            </Route>
          </SimpleLayout>
        </Route>
        <Route>
          <div className={`admin-wrapper`}>
            <Headline>
              {this.isPanoramaOrMap() ? (
                <Link to={this.getDetailLink()} className={`o-bold`}>
                  Back to the detail
                </Link>
              ) : (
                <Logo />
              )}
            </Headline>
            <Header
              isOpen={this.state.isNavOpen}
              toggleNav={this.handleToggleNavBar}
              query={this.state.query}
              setQuery={this.handleSetQuery}
              filter={this.state.filter}
              onFilterChange={
                this.isHomepage() ? this.handleFilterChange : undefined
              }
              onFilterReset={this.handleFilterReset}
            />
            <NavBar
              items={config.navBarItems}
              isOpen={this.state.isNavOpen}
              closeNav={this.closeNavBar}
            />
            <Results
              parks={this.state.parks}
              query={this.state.query}
              hideResults={this.handleHideResults}
              isVisible={this.state.areResultsVisible}
            />
            <Main
              isMoved={isDesktop() ? this.state.isNavOpen : false}
              isFullscreen={this.isPanoramaOrMap()}
              isIframe={this.isIframe()}
            >
              {this.state.hasError ? (
                <p>Something went wrong</p>
              ) : (
                <Suspense fallback={<p>Loading...</p>}>
                  <Route path={config.routes.homepage()} exact>
                    <Homepage
                      parks={this.state.parks}
                      filter={this.state.filter}
                    />
                  </Route>
                  <Route path={config.routes.panoramaParks()} exact>
                    <PanoramaParks parks={this.state.parks} />
                  </Route>
                  {/* iframes */}
                  <Route
                    exact
                    path={config.routes.iframe()}
                    render={({ match }) => <Iframe mapKey={match.params.key} />}
                  />
                  {/*detail*/}
                  <Route
                    exact
                    path={config.routes.parkDetail()}
                    render={({ match }) => (
                      <Detail
                        park={this.state.parks.find(
                          (i) => i.id.toString() === match.params.id
                        )}
                        isLoaded={
                          this.state.parksFetchStatus === STATUS_SUCCESS
                        }
                      />
                    )}
                  />
                  {/*panorama*/}
                  <Route
                    exact
                    path={config.routes.parkPanorama()}
                    render={({ match }) => (
                      <Panorama
                        park={this.state.parks.find(
                          (i) => i.id.toString() === match.params.id
                        )}
                        isLoaded={
                          this.state.parksFetchStatus === STATUS_SUCCESS
                        }
                      />
                    )}
                  />
                  {/*map*/}
                  <Route
                    exact
                    path={config.routes.parkMap()}
                    render={({ match }) => (
                      <Map
                        park={this.state.parks.find(
                          (i) => i.id.toString() === match.params.id
                        )}
                        isLoaded={
                          this.state.parksFetchStatus === STATUS_SUCCESS
                        }
                      />
                    )}
                  />
                </Suspense>
              )}
            </Main>
          </div>
        </Route>
      </Switch>
    )
  }

  /*helpers*/
  isPanoramaOrMap(): boolean {
    return /\d+(\/panorama|\/map)$/.test(this.props.location.pathname)
  }

  isHomepage(): boolean {
    return this.props.location.pathname === `/parks`
  }

  isIframe(): boolean {
    return /iframe/.test(this.props.location.pathname)
  }

  getDetailLink(): string {
    return this.props.location.pathname.replace(/(\/panorama|\/map)/, ``)
  }
}

export default withRouter(App)
