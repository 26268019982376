import * as icon from "./constants/icons"
import { NavBarItems } from "./components/NavBar/NavBar"
import { MapOptions } from "google-map-react"

interface Config {
  navBarItems: NavBarItems
  endpoint: string
  googleMapsApiKey: string
  mapOptions: MapOptions
  panoramaUrl: (id: string) => string
  password: string
  routes: {
    [key: string]: (id?: string) => string
  }
}

const routes = {
  login: () => '/login',
  signpost: () => '/',
  homepage: () => '/parks',
  panoramaParks: () => '/parks-panorama',
  iframe: (key: string = ':key') => `/iframe/${key}`,
  parkDetail: (id?: string) => id ? `/${id}` : `/:id(\\d+)/`,
  parkPanorama: (id?: string) => id ? `/${id}/panorama` : `/:id(\\d+)/panorama`,
  parkMap: (id?: string) => id ? `/${id}/map` : `/:id(\\d+)/map`,
  parkGallery: (id?: string) => `/${id}#gallery`,
  parkBrochure: (id?: string) => `/${id}#brochure`,
}

const config: Config = {
  endpoint: `http://panattoni-api.visualio.cz/`,
  googleMapsApiKey: `AIzaSyDlS7RZTlMVfITDbeyNsKRpYcsmQeOJyAw`,
  panoramaUrl: (id) =>
    `${`http://panattoni-dashboard.visualio.cz/${id}/panorama`}`,
  password: `pnt2020`,
  routes,
  navBarItems: [
    {
      link: routes.homepage(),
      titleLong: `Panattoni Parks`,
      titleShort: `Parks`,
      icon: icon.ICON_HOME,
    },
    {
      link: routes.panoramaParks(),
      titleLong: `Panattoni Parks – 360° View`,
      titleShort: `360°`,
      icon: icon.ICON_360,
    },
    {
      link: routes.iframe('map'),
      titleLong: `Panattoni Parks – Google Map`,
      titleShort: `Maps`,
      icon: icon.ICON_PIN,
    }
  ],
  mapOptions: {
    styles: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#001e44",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8799b3",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#212121",
          },
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            color: "#25a9df",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#001e44",
          },
        ],
      },
      {
        featureType: "administrative.country",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8799b3",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8799b3",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8799b3",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#181818",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8799b3",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#1b1b1b",
          },
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#2f4464",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8a8a8a",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#2f4464",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#2f4464",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [
          {
            color: "#2f4464",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#001629",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8799b3",
          },
        ],
      },
    ],
  }
}

export default config
