import React from "react"
import "./Main.css"
import { cls } from "../../helpers"

interface Props {
  isMoved: boolean
  isFullscreen: boolean
  isSemiFullscreen?: boolean
  isIframe: boolean
  children: React.ReactNode
}

export default function Main({
  isMoved,
  isFullscreen,
  isIframe,
  children,
}: Props) {
  return (
    <main
      className={cls(
        `main`,
        isMoved && `main--moved`,
        isFullscreen && `main--fullscreen`,
        isIframe && `main--iframe`
      )}
    >
      {children}
    </main>
  )
}
